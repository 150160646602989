import React from "react";
import ReactDOM from "react-dom";
import HomePage from "./pages/home";
import Footer from "./components/footer";
import ProjectPage from "./pages/project";
import AboutPage from "./pages/about";
import SustainabilityPage from "./pages/sustainability";
import ContactPage from "./pages/contact";
import Nav from "./components/nav";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Nav />
        <main className="section">
          <Switch>
            <Route exact path="/projects" component={ProjectPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route
              exact
              path="/sustainability"
              component={SustainabilityPage}
            />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/" component={HomePage} />
          </Switch>
        </main>
        <Footer />
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

export default App;
