import React from "react";
import "./about.scss";
import { FaArrowRight } from "react-icons/fa";
import chas from "../assets/images/sustainability/Elite-Badge.png";

const AboutPage = () => {
  return (
    <div className="about-page">
      <img
        className="about-page__hero-img"
        src="https://images.pexels.com/photos/1002740/pexels-photo-1002740.jpeg?cs=srgb&dl=photograph-of-a-woman-in-a-coffee-shop-1002740.jpg&fm=jpg"
        alt="George Busche Carpentry - About"
      />
      <h2 className="about-page__title">About George Busch Carpentry</h2>
      <p className="about-page__text">
        We work in the modern construction world, building everything from
        hand-made furniture to residential renovations and commercial fitout.
      </p>
      <div className="about-page__chas-wrapper">
        <img src={chas} alt="CHAS Elite" />
        <p className="about-page__text">
          As proud members of CHAS Elite we are acredited as practitioners of
          the Common Assessment Method. This means we are prequalified in our
          approach to health and safety and risk management as well as Supply
          Chain best practice.
        </p>
      </div>
      <p className="about-page__text">
        We work in the modern construction world, trying to innervate everything
        from hand-made furniture, commercial renovations to industrial
        construction. We enjoy working with interesting design, while welcoming
        innovation and new technologies. We have developed a reputation for
        delivering quality projects with complex designs, on time and within
        budget.
      </p>
      <h3 className="about-page__sub-title">The story so far:</h3>
      <p className="about-page__text">
        Having trained and qualified as a carpenter and project manager, the
        founder of the company, George Busch, established a reputation for
        high-end residential refurbishments in and around London. Over recent
        years, the second line of the business, commercial interior fit-outs,
        has become popular with clients and is now the company’s main focus. Our
        success is based on open and flexible communication with our clients, on
        a thorough understanding of their needs and priorities and on completing
        projects to their full satisfaction. As a result, most of our projects
        come through client recommendation.
      </p>
      <h3 className="about-page__sub-title">Our values:</h3>
      <ul>
        <li>Clear and concise communication</li>
        <li>Impeccable quality delivered on time</li>
        <li>Sustainability within the Built Environment</li>
        <li>Ethical practices and support of the local community</li>
      </ul>

      <a
        className="about-page__cta"
        href="mailto:george@georgebuschcarpentry.com"
      >
        GET IN TOUCH <FaArrowRight />
      </a>
    </div>
  );
};

export default AboutPage;
