import React from "react";
import "./home.scss";
import logo from "../assets/images/gbc-logo.png";
import { Link } from "react-router-dom";
import image1 from "../assets/images/home/1.jpg";
import image2 from "../assets/images/home/2.jpg";
import image3 from "../assets/images/home/3.jpg";
import image4 from "../assets/images/home/4.jpg";
import image5 from "../assets/images/home/5.jpg";
import image6 from "../assets/images/home/6.jpg";
import image7 from "../assets/images/home/7.jpg";
import image8 from "../assets/images/home/8.jpg";
import image9 from "../assets/images/home/9.jpg";

const HomePage = () => {
  return (
    <div className="home-page">
      <ul className="home-page__items">
        <li className="home-page__item">
          <Link to="/contact">
            <img
              className="home-page__item-img"
              src={logo}
              alt="GEORGEBUSCHCARPENTRY project"
            />
          </Link>
        </li>

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image1}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image2}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image3}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>

        {/* A JSX comment */}

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image4}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image5}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image6}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>

        <li className="home-page__item">
          <Link to="/projects">
            <div className="home-page__item-box">
              <h3 className="home-page__item-box-title">Projects</h3>
            </div>
          </Link>
        </li>

        {/* A JSX comment */}

        <li className="home-page__item">
          <Link to="/about">
            <div className="home-page__item-box">
              <h3 className="home-page__item-box-title">About</h3>
            </div>
          </Link>
        </li>

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image7}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image8}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>

        <li className="home-page__item">
          <img
            className="home-page__item-img"
            src={image9}
            alt="GEORGEBUSCHCARPENTRY project"
          />
        </li>
      </ul>
    </div>
  );
};

export default HomePage;
