import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <a
          className="blink"
          href="https://www.weareblink.co.uk"
          target="__blank"
        >
          website by BLINK
        </a>
        <p>&#169; George Busch Carpentry. 2023</p>
      </div>
      <p>
        <a href="mailto:george@georgebuschcarpentry.com">
          george@georgebuschcarpentry.com
        </a>
        <a href="tel:+442085317709">+44 (0) 2085 317 709</a>
        <a href="tel:+447795104493">+44 (0)779 5104 493</a>
      </p>
    </div>
  );
};

export default Footer;
