import React from "react";
import "./contact.scss";
import { FaInstagram, FaRegEnvelope } from "react-icons/fa";
import { AiOutlinePhone } from "react-icons/ai";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <img
        className="contact-page__hero-img"
        src="https://images.pexels.com/photos/1002740/pexels-photo-1002740.jpeg?cs=srgb&dl=photograph-of-a-woman-in-a-coffee-shop-1002740.jpg&fm=jpg"
        alt="George Busche Carpentry project and contact"
      />
      <h2 className="contact-page__title">Contact Us</h2>
      <a
        href="mailto:george@georgebuschcarpentry.com"
        className="contact-page__contact"
      >
        <FaRegEnvelope /> george@georgebuschcarpentry.com
      </a>
      <a
        href="https://www.instagram.com/georgebuschcarpentry/"
        className="contact-page__contact"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram /> @georgebuschcarpentry
      </a>
      <a href="tel:+442085317709" className="contact-page__contact">
        <AiOutlinePhone /> +44 (0) 2085 317 709
      </a>
      <a href="tel:+447795104493" className="contact-page__contact">
        <AiOutlinePhone /> +44 (0)779 5104 493
      </a>
    </div>
  );
};

export default ContactPage;
