import React from "react";
import "./project.scss";
import image1 from "../assets/images/projects/1.jpg";
import image2 from "../assets/images/projects/2.jpg";
import image3 from "../assets/images/projects/3.jpg";
import image4 from "../assets/images/projects/4.jpg";
import image5 from "../assets/images/projects/5.jpg";
import image6 from "../assets/images/projects/6.jpg";
import image7 from "../assets/images/projects/7.jpg";
import image8 from "../assets/images/projects/8.jpg";

const ProjectPage = () => {
  return (
    <div className="project-page">
      <h2 className="project-page__title">Projects</h2>
      <ul className="project-page__items">
        <li className="project-page__item">
          <img
            className="project-page__item-img"
            src={image1}
            alt="Fit out - Restaurant"
          />
          <div className="project-page__item-content">
            <h3 className="project-page__item-title">Hackney Wick</h3>
            <p className="project-page__item-subtext">
              Design and build of bespoke joinery for restaurant in Hackney
              Wick.
            </p>
          </div>
        </li>
        <li className="project-page__item">
          <img
            className="project-page__item-img"
            src={image2}
            alt="Fit out - Hair Salon Portobello"
          />
          <div className="project-page__item-content">
            <h3 className="project-page__item-title">Walthamstow</h3>
            <p className="project-page__item-subtext">
              High end residential development involving design and build of
              bespoke joinery.
            </p>
          </div>
        </li>
        <li className="project-page__item">
          <img
            className="project-page__item-img"
            src={image3}
            alt="Fit out - Restaurant London"
          />
          <div className="project-page__item-content">
            <h3 className="project-page__item-title">Aldgate</h3>
            <p className="project-page__item-subtext">
              Design and build of bespoke joinery for restaurant in Aldgate.
            </p>
          </div>
        </li>
        <li className="project-page__item">
          <img
            className="project-page__item-img"
            src={image4}
            alt="Residential extension and full rennovation Chigwell"
          />
          <div className="project-page__item-content">
            <h3 className="project-page__item-title">Chigwell</h3>
            <p className="project-page__item-subtext">
              High end house renovation including loft conversion and kitchen
              extension.
            </p>
          </div>
        </li>
        <li className="project-page__item">
          <img
            className="project-page__item-img"
            src={image5}
            alt="Fit out - Hair Salon Peckham"
          />
          <div className="project-page__item-content">
            <h3 className="project-page__item-title">Wanstead</h3>
            <p className="project-page__item-subtext">
              Conversion of restaurant space into hair salon and shop for
              London's first sustainable salon group using sustainable materials
              & practices.
            </p>
          </div>
        </li>
        <li className="project-page__item">
          <img
            className="project-page__item-img"
            src={image6}
            alt="Fit out - Office Shoreditch"
          />
          <div className="project-page__item-content">
            <h3 className="project-page__item-title">Shoreditch</h3>
            <p className="project-page__item-subtext">
              Adaption of 19th Century listed warehouse into 1st floor office
              space in Shoreditch.
            </p>
          </div>
        </li>
        <li className="project-page__item">
          <img
            className="project-page__item-img"
            src={image7}
            alt="Fit out - Hair Salon Streatham"
          />
          <div className="project-page__item-content">
            <h3 className="project-page__item-title">Bow</h3>
            <p className="project-page__item-subtext">
              Conversion of Victorian residential property into 2 storey hair
              salon.
            </p>
          </div>
        </li>
        <li className="project-page__item">
          <img
            className="project-page__item-img"
            src={image8}
            alt="Fit out - Office Bethnal Green"
          />
          <div className="project-page__item-content">
            <h3 className="project-page__item-title">Bethnal Green</h3>
            <p className="project-page__item-subtext">
              Office fit out heavily focussed on using reclaimed materials and
              restoring original features.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ProjectPage;
