import React from "react";
import "./sustainability.scss";

import hero from "../assets/images/sustainability/sustainability-hero.jpg";
import clouds from "../assets/images/sustainability/clouds.jpg";
import recycle from "../assets/images/sustainability/recycle.jpg";
import workshop from "../assets/images/sustainability/workshop.jpg";

import { FaArrowRight } from "react-icons/fa";

const ProjectPage = () => {
  return (
    <div className="sustainability-page">
      <img
        className="sustainability-page__hero-img"
        src={hero}
        alt="Tree branches"
      />
      <h2 className="sustainability-page__title">Sustainability</h2>
      <p className="sustainability-page__intro">
        At George Busch Carpentry we do what we can to respect the environment –
        from using low-emission vehicles to minimising waste and embracing
        technology based on renewable energy sources.
      </p>
      <ul className="sustainability-page__items">
        <li className="sustainability-page__item">
          <img alt="Clouds" src={clouds}></img>
          <div>
            <h2>How we champion more sustainable products for our clients:</h2>
            <ul>
              <li>Bettering U values through increased insulation </li>

              <li>Predominant use of LED lighting </li>

              <li>Daylighting to reduce reliance on artificial lighting </li>

              <li>
                Temperature control through design & use of technologies such as
                smart stats
              </li>

              <li>Photovoltaic energy </li>

              <li>Green roofs to encourage biodiversity </li>

              <li>Infrared heating alternative to gas </li>
            </ul>
          </div>
        </li>
        <li className="sustainability-page__item">
          <img alt="A recycling sign" src={recycle}></img>
          <div>
            <h2>Our sustainability strategy & practises:</h2>
            <ul>
              <li>
                Reduction of waste through best practice in construction and
                waste management{" "}
              </li>

              <li>Championing the reuse of reclaimed materials </li>

              <li>Supporting a circular economy including take back schemes</li>

              <li>Adhering to a diesel free site</li>

              <li>
                Advocating the use of materials with less carbon footprint{" "}
              </li>

              <li>Ensuring a responsible procurement policy</li>

              <li>
                Procuring FSC timber with a timber chain of custody certificate{" "}
              </li>
            </ul>
          </div>
        </li>
        <li className="sustainability-page__item">
          <img alt="A persons hands cutting wood" src={workshop}></img>
          <div>
            <h2>Our community:</h2>
            <ul>
              <li>Employing locally in North & East London</li>

              <li>Apprenticeship & mentoring programme</li>

              <li>Sponsoring local community gardens </li>

              <li>Swift box scheme</li>
            </ul>
          </div>
        </li>
      </ul>
      <a
        className="sustainability-page__cta"
        href="mailto:george@georgebuschcarpentry.com"
      >
        GET IN TOUCH <FaArrowRight />
      </a>
    </div>
  );
};

export default ProjectPage;
