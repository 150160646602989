import React from "react";
import "./nav.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../assets/images/gbc-logo.png";
import { Link } from "react-router-dom";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navIsOpen: false,
    };
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleNavToggle = () => {
    this.setState({ navIsOpen: !this.state.navIsOpen });
  };

  handleClick = (e) => {
    if (this.outterNav.contains(e.target)) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({ navIsOpen: false });
  };

  render() {
    return (
      <div ref={(node) => (this.outterNav = node)}>
        <div onClick={this.handleNavToggle} className="nav__hamburger-icon">
          <GiHamburgerMenu />
        </div>
        <nav className={this.state.navIsOpen ? "nav nav--is-open" : "nav"}>
          <div onClick={this.handleNavToggle} className="nav__hamburger-icon">
            <GiHamburgerMenu />
          </div>
          <ul className="nav__items">
            <li className="nav__item nav__item--is-logo">
              <Link to="/">
                <img className="nav__logo" src={logo} alt="TODO:" />
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/">Home</Link>
            </li>
            <li className="nav__item">
              <Link to="/projects">Projects</Link>
            </li>
            <li className="nav__item">
              <Link to="/sustainability">Sustainability</Link>
            </li>
            <li className="nav__item">
              <Link to="/about">About</Link>
            </li>
            <li className="nav__item">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Nav;
